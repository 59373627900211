// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import { Button, Icon, Input } from "oialbert-ui";
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";

import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Promotion } from "../../../types/promotion";
import errorHandling from "../../../utils/error_handling";
import { AvailabilityDays } from "../../CreatePromotion/components/Form/AvailabilityDays";
import { Description } from "../../CreatePromotion/components/Form/Description";
import { EndDate } from "../../CreatePromotion/components/Form/EndDate";
import { StartDate } from "../../CreatePromotion/components/Form/StartDate";
import { Panel } from "../../CreatePromotion/components/Panel";
import { updateBanner, updatePromotion } from "../../../services/promotions";
import { Tag } from "../../CreatePromotion/components/Form/Tag";
import { zodResolver } from "@hookform/resolvers/zod";
import { Banner } from "../../CreatePromotion/components/Form/Banner";
import { usePromotionContext } from "../../../contexts/PromotionContext";
import classNames from "classnames";

interface EditPromotionModalProps {
  isOpen: boolean;
  closeModal(): void;
  promotion: Promotion;
  afterSave: () => void;
}

const promotionSchema = z
  .object({
    // type: z.enum([TYPE.discount, TYPE.offer, TYPE.combo]),
    name: z
      .string({
        required_error: "campo obrigatório",
      })
      .min(5, { message: "deve ter pelo menos 5 caracteres" })
      .max(170, { message: "deve ter no máximo 170 caracteres" })
      .nonempty({ message: "campo obrigatório" }),
    description: z
      .string({
        required_error: "campo obrigatório",
      })
      .min(12, { message: "deve ter pelo menos 5 caracteres" })
      .max(255, {
        message:
          "deve ter no máximo 255 caracteres, para mais caracteres adicione dentro das regras.",
      })
      .nonempty({ message: "campo obrigatório" }),
    role: z
      .string({
        required_error: "campo obrigatório",
      })
      .min(17, { message: "deve ter pelo menos 10 caracteres" })
      .max(700, { message: "deve ter no máximo 700 caracteres." })
      .nonempty({ message: "campo obrigatório" }),
    promotion_limit_by_customer: z
      .number({
        invalid_type_error: "por favor, insira um número válido",
        required_error: "campo obrigatório",
      })
      .int("por favor, insira um número inteiro")
      .min(0, "O valor deve ser igual a 0 ou maior")
      .default(0),
    tags: z
      .string()
      .array()
      .nonempty({ message: "campo obrigatório" })
      .min(3, { message: "deve ter pelo menos 3 palavras-chave" })
      .max(20, {
        message: "deve ter no máximo 20 palavras-chave, por favor exclua um.",
      }),
    start_date: z.coerce
      .date()
      .refine(
        (data) =>
          format(data, "yyyy-MM-dd HH:mm") >
          format(new Date(), "yyyy-MM-dd HH:mm"),
        {
          message: "a data de início deve ser maior que a data atual.",
        }
      )
      .transform((data) => format(data, "yyyy-MM-dd HH:mm")),
    end_date: z.coerce
      .date()
      .transform((data) => format(data, "yyyy-MM-dd HH:mm")),
    banner: z.any().optional(),
    availability: z
      .object({
        days: z
          .string({
            required_error: "campo obrigatório",
          })
          .array(),
      })
      .optional(),
  })
  .refine(
    (data) =>
      format(new Date(data.end_date), "yyyy-MM-dd HH:mm") >=
      format(new Date(data.start_date), "yyyy-MM-dd HH:mm"),
    {
      message: "a data final não pode ser mais antiga que a de inicio.",
      path: ["end_date"],
    }
  );

const EditPromotionModal = ({
  isOpen,
  closeModal,
  promotion,
  afterSave,
}: EditPromotionModalProps) => {
  const transformStringTagsToArray = (tags: string) => {
    const tagsArray = tags.split(",");
    return tagsArray;
  };

  const isReadOnly =
    promotion.status === "actived" || promotion.status === "paused";

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(promotionSchema),
    defaultValues: {
      name: promotion.name,
      start_date: promotion.start_datetime,
      end_date: promotion.end_datetime,
      description: promotion.description,
      promotion_limit_by_customer: promotion.promotion_limit_by_customer,
      role: promotion.role,
      availability: promotion.availability,
      tags: transformStringTagsToArray(promotion.tags),
      type: promotion.type,
      discount_amount: promotion.discount_amount,
      status: promotion.status,
    },
  });

  const [previewBanner, setPreviewBanner] = useState<string | undefined>();
  const { applyPreview, preview } = usePromotionContext();
  const [loading, setLoading] = useState(false);
  const [openEditPromotionModalConfirm, setOpenEditPromotionModalConfirm] =
    useState(false);
  function handleCloseEditPromotionModalConfirm() {
    setOpenEditPromotionModalConfirm(false);
  }
  function handleOpenEditPromotionModalConfirm() {
    setOpenEditPromotionModalConfirm(true);
  }

  type FormValues = z.infer<typeof promotionSchema>;

  const handleCloseAndResetForm = useCallback(() => {
    closeModal();
    reset();
  }, [closeModal, reset]);

  const savePromotion = useCallback(
    async (data: FormValues) => {
      setLoading(true);

      try {
        if (promotion?.id) {
          const payload = {
            ...promotion,
            name: data.name,
            start_datetime: data.start_date,
            end_datetime: data.end_date,
            description: data.description,
            promotion_limit_by_customer: data.promotion_limit_by_customer,
            role: data.role,
            availability: Object(data.availability),
            tags: String(data.tags),
          };

          await updatePromotion(promotion.id, payload);

          data?.banner?.file &&
            (await updateBanner(promotion.id, data.banner.file));
          setTimeout(() => {
            errorHandling(
              null,
              "atualizações/alterações realizadas com sucesso.",
              "success"
            );
          }, 1300);
        }
      } catch (error: any) {
        errorHandling(error, "Erro ao salvar promoção");
      } finally {
        setTimeout(() => {
          setLoading(false);
          reset();
          closeModal();
          afterSave();
          handleCloseAndResetForm();
        }, 2000);
      }
    },
    [afterSave, closeModal, handleCloseAndResetForm, promotion, reset]
  );

  useEffect(() => {
    if (promotion.banner) {
      applyPreview({
        banner: { url: promotion.banner },
      });
    }
  }, [promotion.banner]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Editar promoção
                  </Dialog.Title>
                  {isReadOnly ? (
                    <div
                      className="flex items-center p-4 mt-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 mr-3"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <span className="font-medium">
                          O status da promoção está{" "}
                          {promotion.status === "actived" ? "ativo" : "pausado"}
                          .
                        </span>{" "}
                        você poderá alterar informações relacionadas a data e
                        hora.
                      </div>
                    </div>
                  ) : null}
                  <form
                    className="w-full flex flex-col"
                    onSubmit={handleSubmit(
                      isReadOnly
                        ? savePromotion
                        : handleOpenEditPromotionModalConfirm
                    )}
                  >
                    <Panel title="nome da sua promoção">
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: {
                            value: true,
                            message: "campo obrigarório",
                          },
                        }}
                        render={({ field: { ref, value, ...props } }) => (
                          <Input
                            label=""
                            placeholder="Nome da promoção"
                            className={classNames(
                              "border border-gray-100",
                              isReadOnly ? "bg-gray-200" : ""
                            )}
                            disabled={loading || isReadOnly}
                            value={value ?? ""}
                            error={errors?.name?.message?.toString()}
                            {...props}
                          />
                        )}
                      />
                    </Panel>
                    <Panel title="descrição">
                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { ref, value, ...props } }) => (
                          <Description
                            placeholder="faça uma descrição."
                            value={value ?? ""}
                            {...props}
                            error={errors.description?.message?.toString()}
                            isReadOnly={isReadOnly}
                          />
                        )}
                      />
                    </Panel>
                    <Panel title="regras">
                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { ref, value, ...props } }) => (
                          <Controller
                            control={control}
                            name="role"
                            render={({ field: { ref, value, ...props } }) => (
                              <Description
                                value={value ?? ""}
                                placeholder="descreva as regras."
                                {...props}
                                error={errors.role?.message?.toString()}
                                isReadOnly={isReadOnly}
                              />
                            )}
                          />
                        )}
                      />
                    </Panel>
                    <Panel
                      title={`limite de utilização por associado`}
                      description="informe 0 para ilimitado."
                    >
                      <Controller
                        control={control}
                        name="promotion_limit_by_customer"
                        rules={{
                          required: {
                            value: true,
                            message: "campo obrigatório",
                          },
                          validate: (value) =>
                            value > 0 || "o valor deve ser maior ou igual a 0",
                        }}
                        render={({
                          field: { ref, value, onChange, onBlur, ...props },
                        }) => (
                          <Input
                            type="number"
                            value={value}
                            placeholder="0"
                            disabled
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              onChange(newValue ? Number(newValue) : "");
                            }}
                            onBlur={(e) => {
                              const newValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              onChange(newValue ? Number(newValue) : "");
                              onBlur();
                            }}
                            {...props}
                            error={errors?.promotion_limit_by_customer?.message?.toString()}
                          />
                        )}
                      />
                    </Panel>
                    <Panel
                      title={
                        isReadOnly ? "palavras-chave" : "crie palavras-chave"
                      }
                    >
                      <Controller
                        control={control}
                        name="tags"
                        render={({
                          field: { ref, value, onChange, ...props },
                        }) => (
                          <Tag
                            value={value ?? ""}
                            onChange={onChange}
                            {...props}
                            error={errors.tags?.message?.toString()}
                            isReadOnly={isReadOnly}
                          />
                        )}
                      />
                    </Panel>
                    <Panel title="">
                      <div className="flex gap-3  rounded-lg p-3 gap-3 border relative">
                        <div className="flex flex-col w-1/2">
                          <strong className="text-gray-700 text-xs">
                            selecione a data inicial
                          </strong>
                          <Controller
                            control={control}
                            name="start_date"
                            render={({
                              field: { ref, value, onChange, ...props },
                            }) => (
                              <StartDate
                                loading={false}
                                onChange={onChange}
                                value={value}
                                error={errors.start_date?.message?.toString()}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-col w-1/2">
                          <strong className="text-gray-700 text-xs">
                            selecione a data final
                          </strong>
                          <Controller
                            control={control}
                            name="end_date"
                            render={({
                              field: { ref, value, onChange, ...props },
                            }) => (
                              <EndDate
                                loading={false}
                                value={value}
                                onChange={onChange}
                                error={errors.end_date?.message?.toString()}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </Panel>
                    <Panel
                      title="disponibilidade"
                      description="ative os dias em que essa promoção será veículada."
                    >
                      <Controller
                        control={control}
                        name="availability[days]"
                        render={({
                          field: { ref, value, onChange, ...props },
                        }) => (
                          <AvailabilityDays
                            onChange={onChange}
                            error={errors?.availability?.days?.message?.toString()}
                            value={value}
                          />
                        )}
                      />
                    </Panel>
                    <Panel
                      title={
                        isReadOnly
                          ? "o upload do banner ja foi feito"
                          : "faça o upload do banner"
                      }
                      description={
                        isReadOnly
                          ? ""
                          : "certifique-se de que sua imagem esteja no formato JPG, JPEG ou PNG. recomendamos uma dimensão ideal de 1920x1080 pixels com uma proporção de 16:9."
                      }
                    >
                      <>
                        <div className="relative mx-auto flex justify-center items-center  ">
                          {!isReadOnly ? (
                            <div className=" flex items-center justify-center border p-3 rounded w-full">
                              <Icon.MdUploadFile className="h-5 w-5 text-gray-700 text-opacity-40 mr-3" />
                              <span className="text-gray-700 text-opacity-50 text-xs">
                                {" "}
                                carregar outra imagem
                              </span>
                              <input
                                type="file"
                                id="desktop-user-photo"
                                name="user-photo"
                                onChange={(e: ChangeEvent) => {
                                  const target = e.target as HTMLInputElement;
                                  const file: File = (
                                    target.files as FileList
                                  )[0];
                                  const fileUrl = URL.createObjectURL(file);
                                  setPreviewBanner(fileUrl);
                                  applyPreview({
                                    banner: { url: fileUrl },
                                  });
                                }}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md p-2"
                              />
                            </div>
                          ) : null}
                        </div>
                        {preview?.banner && (
                          <div className="h-48 relative mt-5">
                            <Controller
                              control={control}
                              name="banner"
                              render={({
                                field: { ref, onChange, ...props },
                              }) => (
                                <Banner
                                  onChange={onChange}
                                  previewBanner={
                                    previewBanner || promotion.banner
                                  }
                                />
                              )}
                            />
                          </div>
                        )}
                      </>
                    </Panel>
                    <section className="grid sm:grid-cols-2 gap-4 mt-8 mb-5">
                      <Button
                        className="sm:w-1/2"
                        onClick={handleCloseAndResetForm}
                        variant="outline"
                        color="neon"
                        type="button"
                        full
                      >
                        Cancelar
                      </Button>
                      <Button
                        className="sm:w-1/2"
                        full
                        variant="solid"
                        color="neon"
                      >
                        Atualizar
                      </Button>
                    </section>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={openEditPromotionModalConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={handleCloseEditPromotionModalConfirm}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Editar promoção
                  </Dialog.Title>
                  <p className="mt-4 text-base mx-auto">
                    hey! Sua edição está sendo analisada. a gente te avisa
                    quando a promoção for aprovada ou recusada!
                  </p>
                  <form
                    className="w-full flex flex-col"
                    onSubmit={handleSubmit(savePromotion)}
                  >
                    <section className="grid sm:grid-cols-2 gap-4 mt-8 mb-5">
                      <Button
                        className="sm:w-1/2"
                        onClick={handleCloseEditPromotionModalConfirm}
                        variant="outline"
                        color="neon"
                        type="button"
                        full
                      >
                        Cancelar
                      </Button>
                      <Button
                        className="sm:w-1/2"
                        full
                        variant="solid"
                        color="neon"
                      >
                        Ok
                      </Button>
                    </section>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EditPromotionModal;
