// @ts-nocheck
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { add, format } from "date-fns";
import { Icon, Input } from "oialbert-ui";
import { ChangeEvent, Ref, useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import * as z from "zod";
import { usePromotionContext } from "../../../contexts/PromotionContext";
import useForm from "../../../hooks/useForm";
import { TYPE } from "../../Promotions/types";
import { AvailabilityDays } from "./Form/AvailabilityDays";
import { Banner } from "./Form/Banner";
import { ButtonNext } from "./Form/ButtonNext";
import { Description } from "./Form/Description";
import { EndDate } from "./Form/EndDate";
import Radio from "./Form/Radio";
import { StartDate } from "./Form/StartDate";
import { Tag } from "./Form/Tag";
import { Panel } from "./Panel";
import Progressivebar from "./ProgressiveBar";
import { discountAmount } from "../../../utils/is_number";
import useAuth, { UseAuthProps } from "../../../hooks/useAuth";
import { CompanyDetailsData } from "../../../types/companies";

type FormProps = {
  formRef: Ref<HTMLFormElement>;
  onSubmit: (_payload: any) => void;
};

type AuthProps = {
  company: CompanyDetailsData | any;
  setCompany: (_company: UseAuthProps["company"]) => void;
};

const Form = ({ formRef, onSubmit }: FormProps) => {
  const { company }: AuthProps = useAuth();
  const PromotionSchema = z
    .object({
      type: z.enum([TYPE.discount, TYPE.offer, TYPE.combo]),
      discount_amount: z
        .number({
          invalid_type_error: "por favor, insira um número válido",
          required_error: "campo obrigatório",
        })
        .refine((value) => value > company.cashback_default_in_percent, {
          message: `o valor deve ser maior que ${company.cashback_default_in_percent}%`,
        }),
      name: z
        .string({
          required_error: "campo obrigatório",
        })
        .min(5, { message: "deve ter pelo menos 5 caracteres" })
        .max(170, { message: "deve ter no máximo 170 caracteres" })
        .nonempty({ message: "campo obrigatório" }),
      description: z
        .string({
          required_error: "campo obrigatório",
        })
        .min(12, { message: "deve ter pelo menos 5 caracteres" })
        .max(255, {
          message:
            "deve ter no máximo 255 caracteres, para mais caracteres adicione dentro das regras.",
        })
        .nonempty({ message: "campo obrigatório" }),
      role: z
        .string({
          required_error: "campo obrigatório",
        })
        .min(17, { message: "deve ter pelo menos 10 caracteres" })
        .max(700, { message: "deve ter no máximo 700 caracteres." })
        .nonempty({ message: "campo obrigatório" }),
      tags: z
        .string({
          required_error: "campo obrigatório",
        })
        .array()
        .nonempty({ message: "campo obrigatório" })
        .min(3, { message: "deve ter pelo menos 3 palavras-chave" })
        .max(20, {
          message: "deve ter no máximo 20 palavras-chave, por favor exclua um.",
        }),
      start_date: z.coerce
        .date()
        .refine(
          (value) => {
            const minAllowedDate = calculateMinAllowedDate();
            return value >= minAllowedDate;
          },
          {
            message:
              "A data deve ser maior que a data atual e estar pelo menos 48 horas a frente.",
          }
        )
        .transform((value) => format(value, "yyyy-MM-dd HH:mm")),
      end_date: z.coerce
        .date()
        .transform((data) => format(data, "yyyy-MM-dd HH:mm")),
      banner: z.any().optional(),
      availability: z.object({
        days: z
          .string({
            required_error: "campo obrigatório",
          })
          .array()
          .nonempty({ message: "para continuar você deve selecionar os dias" }),
      }),
      promotion_limit_by_customer: z
        .number({
          invalid_type_error: "por favor, insira um número válido",
          required_error: "campo obrigatório",
        })
        .int("por favor, insira um número inteiro")
        .min(0, "O valor deve ser igual a 0 ou maior")
        .default(0),
    })
    .refine(
      (data) =>
        format(new Date(data.end_date), "yyyy-MM-dd HH:mm") >=
        format(new Date(data.start_date), "yyyy-MM-dd HH:mm"),
      {
        message: "a data final não pode ser mais antiga que a de inicio.",
        path: ["end_date"],
      }
    );

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const {
    applyPreview,
    preview,
    loading: loadingCreatePromotion,
  } = usePromotionContext();
  const [loading, setLoading] = useState(false);
  const [previewBanner, setPreviewBanner] = useState<string | undefined>();

  function getInitialDate() {
    let startDate = add(new Date(), { days: 10 });
    startDate = add(startDate, { minutes: 2 });
    const formattedDate = format(startDate, "yyyy-MM-dd HH:mm");
    return formattedDate;
  }

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      type: TYPE.combo,
      start_date: getInitialDate(),
      end_date: format(add(new Date(), { days: 30 }), "yyyy-MM-dd HH:mm"),
      availability: {
        days: [],
      },
      promotion_limit_by_customer: 0,
    },
    schema: PromotionSchema,
  });

  const [type, discount_amount] = watch(["type", "discount_amount"]);

  function calculateMinAllowedDate() {
    const now = new Date();
    const minAllowedDate = new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000);

    return minAllowedDate;
  }

  const nextStep = useCallback(
    (step: number) => {
      if (step === 0 && ((type && discount_amount) || type === TYPE.combo)) {
        return true;
      }
      const stepOne = ["name", "description", "role", "tags"];
      const enabledStepOne = stepOne.some((field) =>
        Object.keys(errors).includes(field)
      );
      if (step === 1 && !enabledStepOne) {
        return true;
      }

      const stepTwo = ["start_date", "end_date", "availability"];
      const enabledStepTwo = stepTwo.some(
        (field) => Object.keys(errors).includes(field) && errors[field]
      );

      if (step === 2 && !enabledStepTwo) {
        return true;
      }
      const stepThree = ["banner"];
      const enabledStepThree = stepThree.some((field) =>
        Object.keys(errors).includes(field)
      );
      if (step === 3 && !enabledStepThree) {
        return true;
      }
      return false;
    },
    [type, discount_amount, errors]
  );

  useEffect(() => {
    trigger();
    const { unsubscribe } = watch((preview) => {
      if (preview.availability?.days?.length === 0) {
        setError("availability", {
          message: "Precisa adicionar os dias de disponibilidade",
          type: "error",
        });
      } else {
        clearErrors("availability");
      }
      applyPreview(preview);
    });
    return () => {
      unsubscribe();
    };
  }, [applyPreview, clearErrors, setError, trigger, watch]);

  return (
    <form
      ref={formRef}
      className="w-full flex flex-col"
      encType="multipart/form-data"
      onSubmit={handleSubmit(async (data: any) => {
        setLoading(true);

        if (!data.banner) {
          setError("banner", {
            type: "error",
            message:
              "banner é obrigatório, selecione a imagem e clique em recortar.",
          });
        } else {
          onSubmit(data);
        }
        setLoading(false);
      })}
    >
      <div className="flex justify-center items-center gap-3">
        {selectedIndex > 0 && (
          <button
            type="button"
            onClick={() => setSelectedIndex(selectedIndex - 1)}
          >
            <Icon.MdArrowBack className="h-6 w-6" />
          </button>
        )}
        <Progressivebar width={`${100 / (3 / selectedIndex)}%`} />
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List>
          {[0, 1, 2, 3].map((_, index) => (
            <Tab key={`tab-base-register-steps-${index}`}></Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={`tab-panel-register-steps-${0}`}>
            <Panel
              title="Você pode escolher 3 tipos de promoções especiais para o associado."
              description={`<strong>combo:</strong> adicione vários produtos ou serviços em uma única
                promoção, oferecendo uma única porcentagem de cashback sobre todos os produtos.  <br />
                <strong>oferta:</strong> escolha um único produto ou serviço que queira divulgar e aplique uma porcentagem de cashback especial sobre ele.
                <br />
                <strong>desconto:</strong> porcentagem especial no estabelecimento todo. porcentagem de desconto na sua loja.`}
            >
              <Controller
                control={control}
                name="type"
                rules={{
                  required: {
                    value: true,
                    message: "campo obrigarório",
                  },
                }}
                render={({ field: { ref, value, onChange, ...props } }) => (
                  <Radio
                    value={value}
                    onChange={onChange}
                    {...props}
                    error={errors.type?.message?.toString()}
                  />
                )}
              />
            </Panel>

            {(preview?.type === TYPE.discount ||
              preview?.type === TYPE.combo ||
              preview?.type === TYPE.offer) && (
              <Panel
                title={`defina a porcentagem ${
                  preview.type === TYPE.combo
                    ? "do combo"
                    : preview.type === TYPE.offer
                    ? "da oferta"
                    : preview.type === TYPE.discount
                    ? "do desconto"
                    : ""
                }`}
                description="Adicione uma porcentagem de cashback atrativa para os seus clientes, considere aqui a regra de distribuição, ok?!"
              >
                <Controller
                  control={control}
                  name="discount_amount"
                  rules={{
                    required: {
                      value: true,
                      message: "campo obrigatório",
                    },
                    validate: (value) =>
                      parseFloat(value) >= 0 ||
                      "o valor deve ser maior ou igual a 0",
                  }}
                  render={({ field: { ref, value, onChange, ...props } }) => (
                    <Input
                      type="number"
                      value={value}
                      placeholder="0.0"
                      onChange={(e) => {
                        const newValue = discountAmount(e.target.value);
                        onChange(newValue);
                      }}
                      {...props}
                      error={errors?.discount_amount?.message?.toString()}
                    />
                  )}
                />
              </Panel>
            )}
            <Panel
              title={`limite de utilização por associado`}
              description="informe 0 para ilimitado."
            >
              <Controller
                control={control}
                name="promotion_limit_by_customer"
                rules={{
                  required: {
                    value: true,
                    message: "campo obrigatório",
                  },
                  validate: (value) =>
                    value > 0 || "o valor deve ser maior ou igual a 0",
                }}
                render={({
                  field: { ref, value, onChange, onBlur, ...props },
                }) => (
                  <Input
                    type="number"
                    value={value}
                    placeholder="0"
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^0-9]/g, "");
                      onChange(newValue ? Number(newValue) : "");
                    }}
                    onBlur={(e) => {
                      const newValue = e.target.value.replace(/[^0-9]/g, "");
                      onChange(newValue ? Number(newValue) : "");
                      onBlur();
                    }}
                    {...props}
                    error={errors?.promotion_limit_by_customer?.message?.toString()}
                  />
                )}
              />
            </Panel>
            <ButtonNext
              nextStep={nextStep}
              setIndex={setSelectedIndex}
              index={selectedIndex}
              nextIndex={1}
            />
          </Tab.Panel>
          <Tab.Panel key={`tab-panel-register-steps-${1}`}>
            <Panel
              title="dê um nome para a sua promoção"
              description="crie um nome seja objetivo e chame a atenção dos clientes."
            >
              <Controller
                control={control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: "campo obrigarório",
                  },
                }}
                render={({ field: { ref, value, ...props } }) => (
                  <Input
                    label=""
                    placeholder="Nome da promoção"
                    className="border border-gray-100"
                    disabled={false}
                    value={value ?? ""}
                    error={errors?.name?.message?.toString()}
                    {...props}
                  />
                )}
              />
            </Panel>
            <Panel
              key={`panel-register-steps-${0}`}
              title="faça uma descrição"
              description="crie uma descrição da sua promoção que chame a atenção dos clientes."
            >
              <Controller
                control={control}
                name="description"
                render={({ field: { ref, value, ...props } }) => (
                  <Description
                    placeholder="faça uma descrição."
                    value={value ?? ""}
                    {...props}
                    error={errors.description?.message?.toString()}
                  />
                )}
              />
            </Panel>
            <Panel
              key={`panel-register-steps-${1}`}
              title="crie as regras"
              description="descreva como deverá ser as regras da promoção, por ex: somente
                uma peça, na compra acima de R$ 100,00."
            >
              <Controller
                control={control}
                name="description"
                render={({ field: { ref, value, ...props } }) => (
                  <Controller
                    control={control}
                    name="role"
                    render={({ field: { ref, value, ...props } }) => (
                      <Description
                        value={value ?? ""}
                        placeholder="descreva as regras."
                        {...props}
                        error={errors.role?.message?.toString()}
                      />
                    )}
                  />
                )}
              />
            </Panel>
            <Panel
              key={`panel-register-steps-${2}`}
              title="crie palavras-chave"
              description="para facilitar as buscas da sua promoção crie uma lista com até
                20 palavras sobre a sua promoção. aperte enter para confirmar a palavra."
            >
              <Controller
                control={control}
                name="tags"
                render={({ field: { ref, value, onChange, ...props } }) => (
                  <Tag
                    value={value ?? ""}
                    onChange={onChange}
                    {...props}
                    error={errors.tags?.message?.toString()}
                  />
                )}
              />
            </Panel>
            <ButtonNext
              nextStep={nextStep}
              setIndex={setSelectedIndex}
              index={selectedIndex}
              nextIndex={2}
            />
          </Tab.Panel>
          <Tab.Panel key={`tab-panel-register-steps-${2}`}>
            <Panel
              key={`panel-register-steps-${3}`}
              title="para iniciar a promoção"
              description="selecione a data inicial e a data final da veiculação da
                promoção."
            >
              <div className="flex gap-3  rounded-lg p-3 gap-3 border relative">
                <div className="flex flex-col w-1/2">
                  <strong className="text-gray-700 text-xs">
                    selecione a data inicial
                  </strong>
                  <Controller
                    control={control}
                    name="start_date"
                    render={({ field: { ref, value, onChange } }) => (
                      <StartDate
                        loading={false}
                        onChange={onChange}
                        value={value ?? ""}
                        error={errors.start_date?.message?.toString()}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <strong className="text-gray-700 text-xs">
                    selecione a data final
                  </strong>
                  <Controller
                    control={control}
                    name="end_date"
                    render={({ field: { ref, value, onChange, ...props } }) => (
                      <EndDate
                        loading={false}
                        value={value ?? ""}
                        onChange={onChange}
                        error={errors.end_date?.message?.toString()}
                      />
                    )}
                  />
                </div>
              </div>
            </Panel>
            <Panel
              title="disponibilidade"
              description="ative os dias em que essa promoção será veículada."
            >
              <Controller
                control={control}
                name="availability[days]"
                render={({ field: { ref, value, onChange, ...props } }) => (
                  <AvailabilityDays
                    {...props}
                    onChange={onChange}
                    value={value}
                    error={errors?.availability?.days?.message?.toString()}
                  />
                )}
              />
            </Panel>
            {errors.end_date?.message || errors?.availability?.days?.message ? (
              <button
                type="button"
                disabled
                className={classNames(
                  "bg-gray-100 cursor-block my-3 flex justify-center items-center py-2 px-4 rounded-lg "
                )}
              >
                próximo
              </button>
            ) : (
              <ButtonNext
                nextStep={nextStep}
                setIndex={setSelectedIndex}
                index={selectedIndex}
                nextIndex={3}
              />
            )}
          </Tab.Panel>
          <Tab.Panel key={`tab-panel-register-steps-${3}`}>
            <Panel
              title="faça o upload do banner"
              description="certifique-se de que sua imagem esteja no formato JPG, JPEG ou
                PNG. recomendamos uma dimensão ideal de 1920x1080 pixels com uma
                proporção de 16:9."
            >
              <>
                <div className="relative mx-auto flex justify-center items-center">
                  <div className=" flex items-center justify-center border p-3 rounded w-full">
                    <Icon.MdUploadFile className="h-5 w-5 text-gray-700 text-opacity-40 mr-3" />
                    <span className="text-gray-700 text-opacity-50 text-xs">
                      {" "}
                      carregar outra imagem
                    </span>
                  </div>
                  <input
                    type="file"
                    id="desktop-user-photo"
                    name="user-photo"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e: ChangeEvent) => {
                      const target = e.target as HTMLInputElement;
                      const file: File = (target.files as FileList)[0];
                      const fileUrl = URL.createObjectURL(file);
                      setPreviewBanner(fileUrl);
                      applyPreview({
                        banner: { url: fileUrl },
                      });
                    }}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md p-2"
                  />
                </div>
                {preview?.banner && (
                  <div className="h-48 relative mt-5">
                    <Controller
                      control={control}
                      name="banner"
                      render={({ field: { ref, onChange, ...props } }) => (
                        <Banner
                          onChange={onChange}
                          previewBanner={previewBanner}
                        />
                      )}
                    />
                  </div>
                )}
                {loading || loadingCreatePromotion ? (
                  <button
                    disabled={true}
                    className={classNames(
                      "bg-gray-500 text-white cursor-await",
                      "my-2 flex justify-center items-center  py-2 px-4 rounded-lg text-sm"
                    )}
                    type={"button"}
                  >
                    solicitando...
                  </button>
                ) : (
                  <button
                    className={classNames(
                      Object.keys(errors).length > 0
                        ? "bg-gray-500 text-white cursor-not-allowed"
                        : "text-white bg-neon-900 cursor-pointer",
                      "my-2 flex justify-center items-center  py-2 px-4 rounded-lg text-sm"
                    )}
                    type={Object.keys(errors).length > 0 ? "button" : "submit"}
                    disabled={loadingCreatePromotion}
                  >
                    solicitar validação
                  </button>
                )}
                {errors.banner && (
                  <span className="flex flex-col text-neon-900 text-sm">{`* é necessário realizar o upload do banner para publicar a promoção.`}</span>
                )}
                {!errors.banner && Object.keys(errors).length > 0 && (
                  <span className="flex flex-col text-neon-900 text-sm">
                    * para liberar o botão, complete todas as etapas.
                  </span>
                )}
              </>
            </Panel>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </form>
  );
};

export default Form;
