import { Suspense, useEffect } from "react";
import { IonApp, IonNav, isPlatform, setupIonicReact } from "@ionic/react";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { Toast } from "oialbert-ui";
import { Loading } from "./components/Loading";
import Routes from "./routes";
import AuthProvider from "./contexts/AuthContext";
import CameraGalleryProvider from "./contexts/CameraGalleryContext";
import "oialbert-ui/lib/styles/main.css";
import "@ionic/react/css/core.css";
import "@ionic/react/css/structure.css";
import "./safeArea.css";
import "./styles/Swiper.css";
import "./index.css";

// import ProductContextProvider from './contexts/ProductContext';
// import CategoryContextProvider from './contexts/CategoryContext';
// import PromotionContextProvider from './contexts/PromotionContext';

const history = createBrowserHistory();

const TestMode = () => (
  <section className="w-full flex fixed top-0 left-0 bg-neon-900 z-10 items-center justify-center text-white text-sm font-bold">
    ambiente de testes
  </section>
);

const App = () => {
  useEffect(() => {
    setupIonicReact({
      animated: isPlatform("mobile"),
      mode: isPlatform("ios") ? "ios" : "md",
      rippleEffect: true,
    });
  }, []);

  return (
    <IonApp className="safe-area-t safe-area-b bg-white">
      {process.env.REACT_APP_DEMO_MODE &&
        process.env.REACT_APP_DEMO_MODE === "true" && <TestMode />}
      <Suspense fallback={<Loading />}>
        <Router history={history}>
          <AuthProvider>
            <CameraGalleryProvider>
              <Routes />
              <Toast.Toaster />
            </CameraGalleryProvider>
          </AuthProvider>
        </Router>
      </Suspense>
    </IonApp>
  );
};

export default App;
